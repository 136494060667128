<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="/logo-green.png" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5" style="background-color:#f0f9f5 ;">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid style="width: 70%;" :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="row auth-bg px-1 p-2 pt-3" style="border-left: solid 1px #182c25">
         <div class="col-md-12 pr-0 languages-mobile" align="end">
          <b-dropdown
            text="Dark"
            variant="outline-dark"
            id="dropdown-grouped"
            class="dropdown-language no-border-button"
            right
          >
            <template #button-content>
              <b-img
                :src="currentLocale.img"
                height="14px"
                width="22px"
                :alt="currentLocale.locale"
              />
              <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
            </template>
            <b-dropdown-item
              v-for="localeObj in locales"
              :key="localeObj.locale"
              @click="$i18n.locale = localeObj.locale"
            >
              <b-img
                :src="localeObj.img"
                height="14px"
                width="22px"
                :alt="localeObj.locale"
              />
              <span class="ml-50">{{ $t(localeObj.name) }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <b-col sm="12" md="12" lg="12" class="px-xl-2 mx-auto mt-2">
          <div class="row">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1 col-md-12">
              {{ $t("welcome_to_hive_administration") }}
            </b-card-title>
         
            <b-card-text class="mb-2 col-md-12">
              {{ $t("login_subtitle") }}
            </b-card-text>
          </div>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group :label="$t('email')" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                    :disabled="disableInputs"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t("password") }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                      :disabled="disableInputs"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <p class="text-right">
                    <b-link :to="{ name: 'forgot-password' }">
                      <small>{{ $t("forgot_password") }} </small>
                    </b-link>
                  </p>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ $t("remember_me") }}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ $t("sign_in") }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v5.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      disableInputs: false,
      locales: [
        {
          locale: "en",
          img: "/images/flags/en.png",
          name: "english",
        },

        {
          locale: "de",
          img: "/images/flags/german.png",
          name: "german",
        },
      ],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    currentLocale() {
      let obj = null;
      if (this.$i18n.locale) {
        if (this.$i18n.locale == "en") {
          obj = {
            locale: "en",
            img: "/images/flags/en.png",
            name: "english",
          };
        } else {
          obj = {
            locale: "de",
            img: "/images/flags/german.png",
            name: "german",
          };
        }
      } else {
        obj = {
          locale: "en",
          img: "/images/flags/en.png",
          name: "english",
        };
      }
      this.$store.commit("app/UPDATE_LANGUAGE", obj.locale);
      return obj;
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.disableInputs = true;

          this.$http
            .post("/login", {
              email: this.userEmail,
              password: this.password,
              remember: this.status,
              language: this.currentLocale.locale,
            })
            .then((res) => {
              if (res.data.status === 200) {
                const user = res.data.value;
                this.$store.commit("auth/SET_AUTHENTICATED", true);
                this.$store.commit("auth/SET_USER", user);
                this.$store.commit("auth/UPDATE_TOKEN", user.token);
                if (user.status == 1) {
                  window.location.href = "/";
                } else {
                  this.$router.push("/verify");
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("successfully_logged_in"),
                    icon: "SuccessIcon",
                    variant: "success",
                  },
                });
              } else {
                this.$store.commit("auth/UPDATE_TOKEN", null);
                this.disableInputs = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t(
                      "failed_to_authenticate_with_provided_credentials."
                    ),
                    icon: "ErrorIcon",
                    variant: "error",
                  },
                });
              }
            })
            .catch(() => {
              this.$store.commit("auth/SET_USER", []);
              this.$store.commit("auth/SET_AUTHENTICATED", false);
              this.$store.commit("auth/UPDATE_TOKEN", null);
              this.disableInputs = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t(
                    "failed_to_authenticate_with_provided_credentials."
                  ),
                  icon: "ErrorIcon",
                  variant: "error",
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
<style>
.no-border-button button {
  border: none !important;
}
</style>
